<template>
  <header class="iv2-global">
    <div class="iv2-global__items theme__global-bg">
      <h1 class="iv2-global__logo">
        <router-link
          v-if="information.sp_logo_image_header"
          :to="'/' + lang + query"
          class="iv2-global__link"
        >
          <picture class="d-none d-md-inline">
            <source
              type="image/webp"
              :srcset="
                information.logo_image_header +
                $store.state.publicLang.general.image_webp
              "
            />
            <img
              :src="information.logo_image_header"
              class="iv2-global__logo-img"
              :alt="organization.name"
            />
          </picture>
          <picture class="d-md-none">
            <source
              type="image/webp"
              :srcset="
                information.sp_logo_image_header +
                $store.state.publicLang.general.image_webp
              "
            />
            <img
              :src="information.sp_logo_image_header"
              class="iv2-global__logo-img"
              :alt="organization.name"
            />
          </picture>
        </router-link>
        <router-link v-else :to="'/' + lang + query" class="iv2-global__link">
          <picture>
            <source
              type="image/webp"
              :srcset="
                information.logo_image_header +
                $store.state.publicLang.general.image_webp
              "
            />
            <img
              :src="information.logo_image_header"
              class="iv2-global__logo-img"
              :alt="organization.name"
            />
          </picture>
        </router-link>
      </h1>
      <div class="iv2-global__menu d-xl-none d-flex text-center m-auto">
        <nav class="iv2-global__sub-menu d-none d-md-flex d-xl-none">
          <div
            v-for="subnav in extra"
            :key="subnav.id"
            class="iv2-global__sub-menu-item text-center d-inline-block"
          >
            <div v-if="subnav.link_type == 'internal'">
              <router-link
                :to="subnav.link_href + lang + query"
                :target="subnav.target_type"
                @click.native="closeMenu"
                class="iv2-global__sub-menu-link theme__global-sns-link text-black"
              >
                <i
                  class="iv2-global__sub-menu-item-icon d-block"
                  :class="subnav.item_icon"
                ></i>
                <span class="iv2-global__sub-menu-item-text d-block">{{
                  subnav.item_name
                }}</span>
              </router-link>
            </div>
            <div v-else-if="subnav.link_type == 'external'">
              <a
                :href="subnav.link_href"
                :target="subnav.target_type"
                @click="closeMenu"
                rel="noopener"
                class="iv2-global__sub-menu-link theme__global-sns-link text-black"
              >
                <i
                  class="iv2-global__sub-menu-item-icon d-block"
                  :class="subnav.item_icon"
                ></i>
                <span class="iv2-global__sub-menu-item-text d-block">{{
                  subnav.item_name
                }}</span>
              </a>
            </div>
          </div>
        </nav>

        <div @click="touchHandler" class="iv2-global__btn d-inline-block">
          <div
            :class="{ active: activeMenu }"
            class="iv2-global__btn-icon position-relative bg-transparent p-0"
          >
            <span class="iv2-global__top-drawer theme__global-icon"></span>
            <span class="iv2-global__center-drawer theme__global-icon"></span>
            <span class="iv2-global__bottom-drawer theme__global-icon"></span>
          </div>
          <span class="iv2-global__menu-text theme__global-text d-block">{{
            menuText
          }}</span>
        </div>
      </div>
    </div>

    <nav
      :class="{ active: activeMenu }"
      class="iv2-nav theme__nav-bg theme__nav-text"
    >
      <div class="iv2-nav__block">
        <ul class="iv2-nav__list list-unstyled mt-xl-3">
          <li
            v-for="nav in global"
            :key="nav.id"
            class="iv2-nav__item theme__nav-item"
            :class="{
              selected: activeNav === nav && nav.children.length > 0,
              active: activeParent == nav.id,
            }"
            @mouseover="activeNav = nav"
            @mouseleave="activeNav = ''"
          >
            <router-link
              v-if="nav.link_type == 'internal'"
              :to="nav.link_href + lang + query"
              @click.native="closeMenu"
              :target="nav.target_type"
              :class="{
                'iv2-nav__link-parent': nav.children.length > 0,
              }"
              class="iv2-nav__link theme__nav-link"
              >{{ nav.item_name }}</router-link
            >
            <a
              v-else-if="nav.link_type == 'external'"
              :href="nav.link_href"
              @click="closeMenu"
              :target="nav.target_type"
              :class="{
                'iv2-nav__link-parent': nav.children.length > 0,
              }"
              rel="noopener"
              class="iv2-nav__link theme__nav-link"
              >{{ nav.item_name }}</a
            >
            <a
              v-if="nav.children.length > 0"
              @click="activeList(nav.id)"
              class="iv2-nav__acordion theme__nav-acordion d-inline-block d-xl-none position-relative"
              href="javascript:void(0)"
            ></a>
            <ul
              v-if="nav.children.length > 0"
              :class="navChildColor"
              class="iv2-nav__list list-unstyled"
            >
              <li
                v-for="cNav in nav.children"
                :key="cNav.id"
                class="iv2-nav__item theme__nav-item--inner"
              >
                <router-link
                  v-if="cNav.link_type == 'internal'"
                  :to="cNav.link_href + lang + query"
                  @click.native="closeMenu"
                  :target="cNav.target_type"
                  class="iv2-nav__link theme__nav-link--inner"
                  >{{ cNav.item_name }}</router-link
                >
                <a
                  v-else-if="cNav.link_type == 'external'"
                  :href="cNav.link_href"
                  @click="closeMenu"
                  :target="cNav.target_type"
                  rel="noopener"
                  class="iv2-nav__link theme__nav-link--inner"
                  >{{ cNav.item_name }}</a
                >
              </li>
            </ul>
          </li>
        </ul>

        <div class="iv2-nav__footer">
          <div class="row m-0 w-100">
            <div class="iv2-nav__footer-item col-12">
              <div
                v-if="organization.tel_number"
                class="iv2-nav__footer-tel d-inline-flex"
              >
                <i class="fas fa-phone-alt mt-3 mr-2"></i>
                <p
                  class="iv2-nav__footer-tel--text mb-2"
                  style="line-height: 1"
                >
                  {{ organization.tel_number }}
                </p>
              </div>

              <div
                v-if="organization.opening_hours"
                class="iv2-nav__footer-time"
              >
                <p
                  v-html="organization.opening_hours"
                  class="size-12 mt-xl-2"
                  style="line-height: 1.3"
                ></p>
              </div>
              <div
                v-if="organization.zip_code"
                class="iv2-nav__footer-adress p-0"
              >
                <p class="size-12 mb-0" style="line-height: 1.3">
                  {{ "〒" + organization.zip_code }}
                </p>
                <div class="mb-3" style="line-height: 1.3">
                  <span
                    v-if="organization.prefecture_name"
                    class="size-12 m-0"
                    >{{ organization.prefecture_name }}</span
                  >
                  <span v-if="organization.city_name" class="size-12 m-0">{{
                    organization.city_name
                  }}</span>
                  <span v-if="organization.town_name" class="size-12 m-0">{{
                    organization.town_name
                  }}</span>
                  <span v-if="organization.building_name" class="size-12 m-0">{{
                    organization.building_name
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-12 d-block d-xl-none text-center">
              <div class="iv2-nav__mobile-sns m-auto">
                <ul
                  v-if="
                    !(
                      sns.is_linked_facebook == 0 &&
                      sns.is_linked_instagram == 0 &&
                      sns.is_linked_twitter == 0 &&
                      sns.is_linked_line == 0
                    )
                  "
                  class="iv2-nav__mobile-sns-list mb-0 list-unstyled"
                >
                  <li
                    v-if="sns.is_linked_facebook == 1"
                    class="iv2-nav__mobile-sns-item"
                  >
                    <a
                      :href="sns.facebook_url"
                      class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                      target="_blank"
                      rel="noopener"
                    >
                      <i class="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li
                    v-if="sns.is_linked_instagram == 1"
                    class="iv2-nav__mobile-sns-item"
                  >
                    <a
                      :href="sns.instagram_url"
                      class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                      target="_blank"
                      rel="noopener"
                    >
                      <i class="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li
                    v-if="sns.is_linked_twitter == 1"
                    class="iv2-nav__mobile-sns-item"
                  >
                    <a
                      :href="sns.twitter_url"
                      class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                      target="_blank"
                      rel="noopener"
                    >
                      <i class="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li
                    v-if="sns.is_linked_line == 1"
                    class="iv2-nav__mobile-sns-item"
                  >
                    <a
                      :href="sns.line_url"
                      class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                      target="_blank"
                      rel="noopener"
                    >
                      <i class="fab fa-line"></i>
                    </a>
                  </li>
                  <!-- <li
                    v-if="rss.is_linked_blog == 1"
                    class="iv2-nav__mobile-sns-item"
                  >
                    <a
                      :href="rss.url"
                      class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                      target="_blank"
                      rel="noopener"
                    >
                      <i class="fas fa-rss"></i>
                    </a>
                  </li> -->
                </ul>
              </div>
              <div
                v-if="organization.copy_right"
                class="iv2-nav__copyright mt-3"
              >
                <small>{{ organization.copy_right }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <!-- フリーページ項目に対応した動的height値指定 -->
    <div is="style">
      @media (min-width: 1200px) {
      {{ setHeight() }}
      }
    </div>
    <!-- フリーページ項目に対応した動的height値指定 -->
  </header>
</template>

<script>
export default {
  props: {
    global: Array,
    extra: Array,
    sns: Object,
    organization: Object,
    information: Object,
    rss: Object,
    count: Number,
  },

  data() {
    return {
      menuText: "",
      activeMenu: true,
      activeParent: false,
      activeNav: "",
      navChildColor: "",
      query: "",
      lang: "",
      environment: "",
    };
  },

  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        if (window.innerWidth <= 1199) {
          this.activeMenu = false;
          this.activeParent = false;
          this.menuText = "MENU";
        }
        const el = document.body;
        el.classList.remove("noscroll");
      }
    },
  },

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.menuText = "MENU";

    if (window.innerWidth <= 1199) {
      this.activeMenu = false;
    } else {
      this.activeMenu = true;
    }
  },

  mounted() {
    if (this.$route.query.mode == "preview") {
      this.query = "?mode=preview";
    }
    if (this.$route.query.hl) {
      this.lang = "?hl=" + this.$route.query.hl;
    }
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    activeList(key) {
      if (this.activeParent === key) {
        this.activeParent = null;
      } else {
        this.activeParent = key;
      }
    },

    handleResize() {
      if (window.innerWidth >= 1199) {
        this.navChildColor = "theme__nav-bg--inner";
      } else {
        this.navChildColor = "";
      }
    },

    touchHandler() {
      this.activeMenu = !this.activeMenu;

      if (this.activeMenu == true) {
        const el = document.body;
        el.classList.add("noscroll");
      } else {
        const el = document.body;
        el.classList.remove("noscroll");
      }

      if (this.activeMenu == true) {
        this.menuText = "CLOSE";
      } else {
        this.menuText = "MENU";
      }
    },

    closeMenu() {
      if (window.innerWidth <= 1199) {
        this.activeMenu = false;
        this.activeParent = false;
        const el = document.body;
        el.classList.remove("noscroll");
        this.menuText = "MENU";
      }
    },

    /** フリーページにより大きく可変するナビゲーションに対応した高さを指定 */
    setHeight() {
      let navHeight = this.count * 30;
      if (navHeight > 350) {
        return (
          ".iv2-global .iv2-nav .iv2-nav__block > .iv2-nav__list .iv2-nav__item > .iv2-nav__list " +
          "{overflow-y: scroll;-ms-overflow-style: none;scrollbar-width: none;}" +
          ".iv2-global .iv2-nav .iv2-nav__block > .iv2-nav__list .iv2-nav__item > .iv2-nav__list::-webkit-scrollbar" +
          "{display:none}"
        );
      }
    },
  },
};
</script>

<style lang="scss">
.iv2-global {
  .iv2-global__items {
    position: relative;
    z-index: 11;

    .iv2-global__logo {
      margin: auto;
      background-color: #fff;
      position: relative;

      .iv2-global__logo-img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .iv2-global__menu {
      top: 50%;
      left: 0;
      right: 0;

      .iv2-global__btn {
        .iv2-global__btn-icon {
          width: 30px;
          height: 20px;
          margin: 0 auto;
          cursor: pointer;

          .iv2-global__top-drawer,
          .iv2-global__center-drawer,
          .iv2-global__bottom-drawer {
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            margin: auto;
            right: 0;
            left: 0;
            transition: 0.3s;
          }

          .iv2-global__top-drawer {
            top: 2px;
          }

          .iv2-global__center-drawer {
            top: 0;
            bottom: 0;
            visibility: visible;
            opacity: 1;
          }

          .iv2-global__bottom-drawer {
            bottom: 2px;
          }

          &.active {
            .iv2-global__top-drawer {
              top: 0;
              bottom: 0;
              transform: rotate(-45deg);
            }

            .iv2-global__center-drawer {
              visibility: hidden;
              opacity: 0;
            }

            .iv2-global__bottom-drawer {
              top: 0;
              bottom: 0;
              transform: rotate(45deg);
            }
          }

          &:focus {
            outline: 0;
          }
        }
      }

      .iv2-global__menu-text {
        font-size: 10px;
        font-weight: 400;
        line-height: 1;
      }
    }
  }

  .iv2-nav {
    width: 100%;
    visibility: hidden;
    display: none;

    &.active {
      visibility: visible;
      display: block;
    }

    .iv2-nav__block {
      > .iv2-nav__list {
        display: inline-block;
        vertical-align: top;

        .iv2-nav__item {
          font-size: 16px;
          letter-spacing: 2px;
          // font-family: Playfair Display, serif;  customize
          font-family: Arial, Helvetica, sans-serif;
          font-weight: bold;

          .iv2-nav__link {
            &:hover {
              text-decoration: none;
            }
          }

          > .iv2-nav__list {
            padding-left: 20px;
            padding-left: 0;

            .iv2-nav__item {
              font-size: 16px;
            }
          }
        }
      }
    }

    .iv2-nav__footer {
      padding: 1.5rem 1.5rem 0;
      margin-left: 0;
      margin-right: 0;

      .iv2-nav__footer-tel {
        width: 100%;

        .iv2-nav__footer-tel--text {
          // font-family: "Times New Roman", "YuMincho", "Hiragino Mincho ProN",
          //   "Yu Mincho", "MS PMincho", "Noto serif JP", serif;  customize
          font-family: Arial, Helvetica, sans-serif;
        }
      }

      .iv2-nav__footer-adress {
        text-align: left;
      }
    }
  }
}

/* PC */
@media (min-width: 1200px) {
  @keyframes tooltipShow {
    from {
      opacity: 0;
      transform: translateX(-20px);
    }

    to {
      opacity: 1;
    }
  }
  @keyframes fade-move {
    0% {
      transform-origin: 0% 0;
      transform: translate3d(0%, 0%, 0px) scale(0, 1);
    }

    100% {
      transform-origin: 0% 0;
      transform: translate3d(0%, 0%, 0px) scale(1, 1);
    }
  }

  .iv2-global {
    text-align: center;

    .iv2-global__items {
      padding-top: 5px;
      position: fixed;

      .iv2-global__logo {
        width: 250px;
        height: 210px;

        .iv2-global__logo-img {
          max-height: 160px;
          max-width: 160px;
        }
      }

      .iv2-global__menu {
        position: absolute;
      }
    }

    .iv2-nav {
      padding-top: 210px;

      .iv2-nav__block {
        > .iv2-nav__list {
          width: 100%;

          .iv2-nav__item {
            font-size: 15px;
            line-height: 2.6;

            > .iv2-nav__list {
              padding-top: 210px;
              width: 0px;
              height: 100vh;
              position: fixed;
              top: 0;
              left: 250px;
              visibility: hidden;
              transition: 0.5s;
              opacity: 0;

              .iv2-nav__item {
                margin-bottom: 15px;
                font-size: 14px;
                line-height: 2;
              }
            }

            &.selected {
              > .iv2-nav__list {
                visibility: visible;
                opacity: 1;
                width: 250px;
              }

              > .iv2-nav__link {
                display: block;
                position: relative;

                &::after {
                  content: "";
                  width: 42px;
                  height: 1px;
                  border-top: 1px solid;
                  display: block;
                  position: absolute;
                  right: 0;
                  top: 50%;
                  animation: fade-move 0.3s cubic-bezier(0, 0.3, 0.25, 1);
                }
              }
            }
          }
        }
      }

      .iv2-nav__footer {
        .iv2-nav__footer-item {
          padding: 0px 17px;

          .iv2-nav__footer-tel {
            width: 100%;
            border-top: 1px solid;
            font-size: 18px;

            .iv2-nav__footer-tel--text {
              font-size: 20px;
            }
          }

          .iv2-nav__footer-adress {
            text-align: left;
            border-top: 1px solid;
          }
        }
      }
    }
  }
}

/* TABLET or SP */
@media (max-width: 1199px) {
  @keyframes tooltipShow {
    from {
      opacity: 0;
      transform: translateY(50px);
    }

    to {
      opacity: 1;
    }
  }

  .iv2-global {
    .iv2-global__items {
      .iv2-global__logo {
        display: inline-block;
        margin-bottom: auto;
        margin-left: 5px;

        .iv2-global__link {
          margin-bottom: 0;
          height: 100%;
          display: inline-block;

          .iv2-global__logo-img {
            max-width: 80%;
            max-height: 80%;
          }
        }
      }

      .iv2-global__menu {
        height: 100%;
        float: right;
        align-items: center;

        .iv2-global__btn {
          line-height: 1;
        }

        .iv2-global__menu-text {
          margin-top: 7px;
        }
      }
    }

    .iv2-nav {
      position: absolute;
      top: 0;
      left: 0;
      padding-top: 64px;
      height: 100vh;
      overflow-y: auto;
      overflow-x: hidden;

      .iv2-nav__sub-menu-item {
        font-size: 1.75em;
        border-right: 1px solid;

        &:last-child {
          border-right: none;
        }
      }

      .iv2-nav__sub-menu-item-text {
        font-size: 0.5em;
        line-height: 1.3;
      }

      .iv2-nav__block {
        padding: 30px 20px 20px;
        animation: tooltipShow 0.5s linear 0s;

        .iv2-nav__list {
          display: block;
          margin: auto;
          padding: 0 64px;
          float: none;

          .iv2-nav__item {
            position: relative;
            display: block;
            margin: 0;

            .iv2-nav__link {
              display: block;
              padding: 15px 0;
            }

            .iv2-nav__link-parent {
              width: 80%;
            }

            > .iv2-nav__list {
              padding: 0;
              visibility: hidden;
              opacity: 0;
              height: 0;

              &.active {
                visibility: visible;
                opacity: 1;
                height: auto;
                transition: 0.5s;
              }
            }

            .iv2-nav__acordion {
              width: 61px;
              height: 45px;
              float: right;
              transform: translateY(-100%);
              bottom: 8px;

              &::before {
                content: "";
                width: 0;
                height: 0;
                position: absolute;
                top: 50%;
                right: 50%;
                transform: translate(50%, -50%);
                transition: 0.2s;
              }
            }

            &.active {
              > .iv2-nav__list {
                visibility: visible;
                opacity: 1;
                height: auto;

                > .iv2-nav__item {
                  padding-left: 20px;
                  font-size: 1em;
                }
              }

              .iv2-nav__acordion {
                &::before {
                  transform: translate(50%, -50%) rotate(90deg);
                }
              }
            }
          }
        }
      }

      .iv2-nav__footer {
        padding: 1.5rem 3rem 6rem;

        .iv2-nav__footer-tel {
          border-bottom: 0.5px solid;
          font-size: 14px;
          padding-bottom: 5px;

          .iv2-nav__footer-tel--text {
            font-size: 17px;
          }
        }

        .iv2-nav__footer-time {
          border-bottom: 0.5px solid;
        }
      }

      .iv2-nav__mobile-sns-list {
        border-top: double 4px;
        border-bottom: double 4px;
        padding: 5px 0;

        .iv2-nav__mobile-sns-item {
          font-size: 1.3em;
          display: inline-block;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-global {
    .iv2-global__items {
      .iv2-global__logo {
        height: 64px;
        width: 200px;
      }

      .iv2-global__menu {
        .iv2-global__btn {
          width: 64px;
        }

        .iv2-global__sub-menu {
          align-items: center;

          .iv2-global__sub-menu-item {
            font-size: 1.5rem;
            width: 80px;

            .iv2-global-push-button {
              padding-top: 3px;
            }

            .iv2-global__sub-menu-link {
              &:hover {
                text-decoration: none;
              }
            }

            .iv2-global__sub-menu-item-text {
              font-size: 10px;
              font-weight: 400;
              margin-top: 6px;
              padding: 0 8px;
              line-height: 1.2;
            }
          }
        }
      }
    }
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-global {
    .iv2-global__items {
      .iv2-global__logo {
        height: 45px;
        width: 116px;
      }

      .iv2-global__menu {
        padding: 5px 10px;

        .iv2-global__menu-text {
          margin-top: 1px;
        }

        .iv2-global__btn {
          width: 45px;

          .iv2-global__btn-icon {
            width: 25px;
          }
        }
      }
    }

    .iv2-nav {
      padding-top: 45px;
      padding-bottom: 10rem;
      padding-bottom: calc(10rem + env(safe-area-inset-bottom));

      .iv2-nav__block {
        .iv2-nav__list {
          padding: 0 21px;

          .iv2-nav__item {
            .iv2-nav__link {
              padding: 15px 5px;
            }
          }
        }
      }

      .iv2-nav__footer {
        padding: 0.4rem;
      }
    }
  }
}
</style>
